import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
//import { User } from './user.types';
import { retry, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  /* private _user: ReplaySubject<User> = new ReplaySubject<User>(1); */
  apiURL = 'https://app.bidimagic.com/public';
  /**
   * Constructor
   */
  constructor(private http: HttpClient) {}

  /*   checkCode(qr, code) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    let body = 'qr=' + qr + '&code=' + code;

    console.log(headers);
    return this.http
      .post<any>(this.apiURL + '/bid/res/checkCode', body, { headers })
      .pipe(retry(1), catchError(this.handleError));
  }
 */
  dologin(qr): Observable<any> {
    console.log('data' + qr);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    let body = 'qr=' + qr;

    console.log(headers);
    return this.http
      .post<any>(this.apiURL + '/bid/res', body, { headers })
      .pipe(retry(1), catchError(this.handleError));
  }
  CheckIfCode(qr): Observable<any> {
    console.log('data' + qr);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    let body = 'qr=' + qr;

    console.log(headers);
    return this.http
      .post<any>(this.apiURL + '/bid/codeExist', body, { headers })
      .pipe(retry(1), catchError(this.handleError));
  }

  CheckCode(code, qr): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    let body = 'qr=' + qr + '&code=' + code;

    console.log(headers);
    return this.http
      .post<any>(this.apiURL + '/bid/checkcode', body, { headers })
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error) {
    console.log(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
