import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DataService} from './home/home.server'
import {MatDialog} from '@angular/material/dialog';
//import { MyModalComponent } from './modal/modal.components';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  classadded:boolean=false;
  orderby: any;
  result:any;
  name: string;
  color: string;
  constructor(private route: ActivatedRoute, private restApi:DataService, private router: Router) {}

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { orderby: "price" }
        this.orderby = params
        console.log(this.orderby); // price
        if(this.orderby.qr){

          this.restApi.dologin(this.orderby.qr)
          .subscribe(async res => {
            console.log(res)
            if(res.qrs!=null){
            this.result=res.qrs[0]
            console.log( this.result)
            if( this.result.tipoProducto==0){
            this.router.navigate(['/home'], { state: this.result });
            }
            else if( this.result.tipoProducto==4){
              this.router.navigate(['/tarjetitas'], { state: this.result });
            }
            }
            else{
              window.open( "https://bidimagic.com/descarga-app/", "_self");
            }
          })
        }
      }
    );
  }
}
